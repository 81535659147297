@service-form-footer-height: 42px;
@service-form-footer-button-width: 100px;

.service-form-page {
    min-height: 100%;
    background-color: var(--background-highlight-color);
}

.native service-form {
    > footer {
        padding-bottom: 51px;
        padding-bottom: calc(51px + env(safe-area-inset-bottom, 0px));
    }
}

service-form {    
    > footer {
        position: fixed;
        z-index: 2;
        padding: 0;
        padding-bottom: env(safe-area-inset-bottom, 0px);
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: @service-form-footer-height;
        height: calc(@service-form-footer-height + env(safe-area-inset-bottom, 0px));
        box-sizing: border-box;
        background: #fff;

        @media @desktop-up {
            left: var(--sidemenu-width);
        }

        .box-shadow-1-inverted();

        button {
            display: -webkit-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: @service-form-footer-height;
            width: @service-form-footer-button-width;
            max-width: 100px;
            padding: 0;
            margin: 0;
            border: none;
            background: none;
            cursor: pointer;
            text-align: center;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;

            &:hover {
                background: rgba(0, 0, 0, 0.12);
            }

            .fa {
                display: block;
                font-size: 22px;
                margin-top: 3px;
            }

            span {
                display: block;
                font-size: 11px;
                margin-top: 2px;
                font-weight: 600;
            }

            &.create {
                height: @service-form-footer-height + 8;
                width: @service-form-footer-height + 8;
                position: relative;
                overflow: hidden;
                background: #000;
                border-radius: @service-form-footer-height + 8;
                margin: 0 5px;
                bottom: 8px;
                color: #fff;
                .box-shadow-1();

                .mdi {
                    height: @service-form-footer-height - 8;
                    line-height: @service-form-footer-height - 8;
                    font-size: 26px;
                }

                &:hover {
                    background: #444;
                }
            }
        }

        filling-list-display {
            display: block;
            width: @service-form-footer-button-width;
        }
    }

    .service-form-content {
        height: 100%;
        box-sizing: border-box;
        padding-bottom: @service-form-footer-height;
        
        @media @desktop-up {
            --max-width: var(--max-width-large);
            margin: 0 auto;
            max-width: var(--max-width);
        }
    }
}

.sf-feedback-rating {
    display: block;
    box-sizing: border-box;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    color: var(--white-color);
    background: var(--highlight-color);
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 4px 7px rgba(60, 72, 88, 0.1);
}

img.sf-feedback-rating {
    object-fit: contain;
    background-color: transparent;
    border-radius: unset;
    box-shadow: none;
    border-color: transparent;
}

@import "./CreateDataListItem/CreateDataListItem";
@import "./CreateUpdateFilling/CreateFilling";
@import "./FillingList/FillingList";
@import "./FillingPreview/FillingPreview";
@import "./FillingsOverview/FIllingsOverview";
@import "./FilterFillings/FilterFilling";
@import "./LiveTile/LiveTile";
@import "./MapPopup/MapPopup";
@import "./ServiceFormField/ServiceFormField";
@import "./Stages/Stages";
@import "./FeedbackForm/FeedbackForm";
@import "FormsOverview/FormsOverviewComponent";



