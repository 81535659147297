@import "../Import.less";

#toast-container {
    bottom: calc(50px + env(safe-area-inset-bottom)) !important;
    padding: var(--spacing);
    transition: transform 0.3s ease;

    @media @tablet-up {
        top: 60px !important; // header height
        bottom: unset !important;
        right: 0;
    }
}

#toast-container > div {

    &.toast {
        border-radius: var(--general-border-radius);
        color: var(--general-text-color);
        opacity: 1;
        position: relative;
        z-index: 0;
        padding: var(--m-spacing);
        width: calc(100vw - (2 * var(--spacing)));
        background-image: none !important;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
        margin: 0;
        position: relative;

        @media @tablet-up {
            max-width: 345px;
            margin-left: auto;
        }

        // white background for rgba color
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            backdrop-filter: blur(20px);
        }

        // new indicator icon
        &::before { 
            position: absolute;
            font-family: @icon-mdi;
            color: var(--white-color);
            margin-right: var(--s-spacing);
            border-radius: 50%;
            font-size: 14px;
            display: inline-flex;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            display: grid;
            place-content: center;
            left: var(--m-spacing);
            top: 50%;
            transform: translate(0, -50%);
        }

        .toast-close-button {
            display: none;

            @media @tablet-up {
                position: absolute;
                display: block;
                color: var(--general-text-inactive-color);
                font-weight: 200;
                top: 50%;
                right: var(--m-spacing);
                transform: translate(0, -50%);
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 26px;
                text-shadow: none;
            }
        }

        .toast-contents {
            display: flex;
            align-items: center;
            margin-left: 30px;
            gap: 2px;

            @media @tablet-up {
                margin-right: 25px;
            }
        }

        .toast-title {
            line-height: 1.3rem;
        }

        .toast-message {
            font-size: 1rem;
            border: none;
            padding: 0;
        }

        .toast-buttons {
            display: flex;
            gap: var(--m-spacing);
            align-items: center;
            margin-left: auto;

            rl-button {
                border-radius: var(--general-border-radius);
            }

            button {
                padding: 0 var(--m-spacing);
                background: inherit;
                min-width: unset;
                font-weight: 600;
                height: 1.875rem;
                color: inherit;
            }

            .button-1 {
                backdrop-filter: blur(12px);
            }

            .button-0 {
                color: var(--white-color);
            }

            &:has(.button-1) {
                flex-basis: 100%;
                justify-content: flex-end;
                margin-top: var(--m-spacing);
            }
        }

        &:has(.button-1) { // two buttons are displayed
            &::before {
                top: unset;
                transform: none;
            }

            .toast-contents {
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;

                @media @tablet-up {
                    margin-right: 0;
                }
            }

            .toast-close-button {
                top: var(--s-spacing);
                right: var(--s-spacing);
                transform: none;
            }
        }

        .spec-toast-mixin(@color, @background-opacity) {
            border: 1px solid @color ;
            background-color: @color;
        
            &::after {
                background-color: rgba(255, 255, 255, @background-opacity);
            }
        
            &::before { 
                color: var(--white-color);
                background: @color;
            }

            .toast-buttons {
                .button-1 button {
                    color: @color;
                }
                .button-0 button {
                    background-color: @color;
                }
            }
        }

        &.toast-error {
            .spec-toast-mixin(var(--warning-color), 0.9);

            &::before { 
                content: "\F156"; /* xmark */ 
                font-size: 11px;
                line-height: 20px;
                font-weight: 800;
            }

            .toast-buttons .button-1 button {
                background-color: rgba(var(--warning-color-rgb), 0.2)
            }
        }

        &.toast-success {
            .spec-toast-mixin(var(--confirmation-color), 0.8);

            &::before { 
                content: "\F12c"; /* check */ 
            }

            .toast-buttons .button-1 button {
                background-color: rgba(var(--confirmation-color-rgb), 0.2)
            }
        }

        &.toast-info {
            .spec-toast-mixin(var(--elevated-text-inactive-color), 0.9);
            color: var(--elevated-text-color);

            &::before { 
                content: "\f2fc"; /* mdi-information*/ 
                color: var(--elevated-text-inactive-color);
                background: var(--elevated-background-color);      
                font-size: 25px; 
                line-height: 25px;
            }
                        
            .toast-buttons .button-1 button {
                background-color: rgba(var(--elevated-text-color-rgb), 0.1)
            }
        }

        &.toast-warning {
            .spec-toast-mixin(var(--alert-color), 0.8);

            &::before { 
                content: "\F028"; /* mdi-alert-circle*/ 
                color: var(--alert-color);
                background: radial-gradient(closest-side, var(--general-text-color) 75%, var(--alert-color));                
                font-size: 25px; 
                line-height: 25px;
            }

            .toast-buttons {
                .button-0 button,
                .button-1 button{
                    color: var(--general-text-color);
                }

                .button-1 button{
                    background-color: rgba(var(--alert-color-rgb), 0.4)
                }
            }
        }
    }
}