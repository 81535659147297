[hashtag-stamped].social-post-item:has(.hashtag-stamp) .hashtag-stamp, // for custom css override
[hashtag-stamped].content-post-item:has(.hashtag-stamp) .hashtag-stamp, // for custom css override
[hashtag-stamped] .hashtag-stamp
 {
    &.top-of-image {
        position: absolute;
        top: var(--m-spacing);
        left: var(--m-spacing);
        z-index: 2;

        width: 60px;
        height: 60px;

        background-image: var(--stamp-url);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.top-of-post {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;

        width: 40px;
        height: 40px;

        background-image: var(--stamp-url);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate(-50%, -55%);
    }

    // Mixin for moving-child styles
    .moving-child(@index, @left, @size, @delay) {
        &:nth-child(@{index}) {
            left: @left;
            width: @size;
            height: @size;
            animation-delay: @delay;
        }
    }

    &.floating,
    &.falling {
        div {
            position: absolute;
            z-index: 2;

            opacity: 0;
            background-image: var(--stamp-url);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;

            .moving-child(1, 0%, 38px, 0.5s);
            .moving-child(2, 5%, 22px, 2s);
            .moving-child(3, 15%, 16px, 5s);
            .moving-child(4, 75%, 28px, 4s);
            .moving-child(5, 85%, 49px, 3s);
            .moving-child(6, 94%, 31px, 1s);
        }
    }

    &.floating {
        div {
            top: calc(100% - 63px);
            animation: 3s hashtag-float linear normal infinite;
            animation-duration: var(--float-time);
        }
    }

    &.falling {
        div {
            top: 0;
            animation: 3s hashtag-fall linear normal infinite;
            animation-duration: var(--float-time);
        }
    }

    // Keyframes
    @keyframes hashtag-float {
        0% {
            opacity: 0;
            transform-origin: center;
            transform: translateY(0) rotate(20deg);
        }
        50% {
            opacity: 1;
            transform-origin: center;
            transform: translateY(calc(var(--float-distance) / 2)) rotate(-20deg);
        }
        100% {
            opacity: 0;
            transform-origin: center;
            transform: translateY(var(--float-distance)) rotate(20deg);
        }
    }

    @keyframes hashtag-fall {
        0% {
            opacity: 0;
            transform-origin: center;
            transform: translateY(0) rotate(20deg);
        }
        50% {
            opacity: 1;
            transform-origin: center;
            transform: translateY(calc(var(--float-distance) / -2)) rotate(-20deg);
        }
        100% {
            opacity: 0;
            transform-origin: center;
            transform: translateY(calc(var(--float-distance) / -1)) rotate(20deg);
        }
    }
}