.base-view.base-view-search {
    background-color: var(--background-highlight-color);
    height: auto;
}

search-page {
    display: block;
    position: relative;
    padding: 0 var(--spacing);

    .sticky-wrapper {
        z-index: 5;
        position: sticky;
        top: 55px;
        background-color: var(--background-highlight-color);

        @media @desktop-up {
            top: 60px;
        }
    }
}

@import "./FilterByModule/FilterByModuleComponent";