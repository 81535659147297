content-create-popup {
    .new-form-label() {
        font-size: 0.9rem;
        font-weight: 600;
        display: block;
    }
    
    .rl-new-form {
        justify-content: flex-start;
        padding-top: 0;
        overflow-y: auto;
    }

    .title-item {
        padding-top: var(--spacing);
    }

    .translation-exclusion-btn {
        margin: var(--s-spacing) 0;
        border-radius: var(--general-border-radius);
    }

    .attachments-wrapper {
        padding: var(--spacing);
        background-color: var(--background-highlight-color);
        border-radius: var(--general-border-radius);
    }

    .language {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--general-border-color);

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 43px;
        }

        language-selector {
            max-width: calc(100% - 50px);
        }

        .translation-btn {
            margin-inline: auto var(--m-spacing);
        }
    }

    .rl-form permission-selector #permissions, 
    .rl-form multiple-permission-selector #permissions, 
    .rl-form multiple-permission-selector-wrapper #permissions,
    multiple-permission-selector .permission-selector-controls, 
    multiple-permission-selector-wrapper .permission-selector-controls {
        background-color: var(--background-highlight-color);
    }

    multiple-permission-selector .selected-items, 
    multiple-permission-selector-wrapper .selected-items {
        & li {
                background-color: var(--general-background-color);
        }
    }

    multiple-permission-selector-wrapper .permission-selector-controls #permissions .selected-items {
        padding: 0;
        padding-inline: 0 30px;
    }

    multiple-permission-selector-wrapper .permissions-label-wrapper {
        border-bottom: none;

        label {        
            .new-form-label();
            color: var(--general-text-color);
            text-transform: unset;
        }
        .asterisk {
            color: var(--highlight-color);
        }
    }

    .multiple-permissions:has(.validation-message .required-field-empty) {
        .permissions-selectors-wrapper{
           border: 1px solid var(--alert-color);
        }
    }

    .multiple-permissions:has(.validation-message .error-message) {
        .permissions-selectors-wrapper{
           border: 1px solid var(--warning-color);
        }
    }

    .time-section {
        & > label {
            .new-form-label();
            padding: 0 var(--m-spacing) var(--s-spacing);
        }

        .rl-input.time > *, .rl-input.time .prefix {
            background-color: var(--background-highlight-color);
        }

        .rl-input.time {
            overflow: hidden;

            & input::before {
                background-color: var(--background-highlight-color);
            }
        }

        .start-time {
            border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
        }

        .end-time {
            border-radius:  0 0 var(--general-border-radius) var(--general-border-radius);
        }

        &:has(.validation-message .required-field-empty) {
            .end-time {
                border: 1px solid var(--alert-color);
            }
        }

        &:has(.validation-message .error-message) {
            .time {
                border: 1px solid var(--warning-color);
            }

            .end-time {
                border-top: none;
            }

            .start-time {
                border-bottom: none;
            }
        }
    }

    .points-section {
        .points-input {
            gap: var(--m-spacing);
        }

        &:has(.validation-message) .points-input{
            border: 1px solid var(--warning-color);
        }
    }

    .notifications {
        .notification-input:not(:last-child) {
            margin-bottom: var(--s-spacing);
        }

        .notification-help-text {
            display: block;
            margin-top: var(--s-spacing);
            color: var(--general-text-inactive-color);
            font-size: 0.8rem;
            font-weight: 300;
        }
    }

    rl-toggle {
        margin-inline: auto 0;
    }

    .toggle-field {
        gap: var(--m-spacing);
    }

    .toggles {
        .toggle-field {
            border-radius: 0;
    
            &:first-child {
                border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
            }
    
            &:last-child {
                border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
            }
    
            &:only-child {
                border-radius: var(--general-border-radius);
            }
        }
    }    

    .form-item:last-child {
        padding-bottom: 75px;
    }

    .post-settings-label {
        .new-form-label();
        padding: 0 var(--m-spacing) var(--s-spacing);
        margin-top: var(--m-spacing);
    }

    .divider-line {
        background-color: var(--general-border-color);
        content: "";
        height: 1px;
        width: 100%;
    }

    .submit {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;

        background: var(--highlight-color);
        color: var(--text-on-highlight-color);

        font-weight: 600;

        &[disabled], &.inactive {
            background: var(--general-text-color);
            opacity: .4;
            color: white;

            &:after {
                position: absolute;
                right: 10px;
                top: 0;
                color: var(--warning-color);

                font-family: "Font Awesome 6\ Pro";
                font-size: 19px;
                font-weight: 900;
                //content: "\f057";
            }
        }
    }

    .category-selector {
        &:has(.validation-message) {
            .content-category {
                border: 1px solid var(--alert-color);
            }
        }
    
        .content-category {
            min-height: 50px;
            background: var(--background-highlight-color);
            gap: 10px;
            display: flex !important;
            flex-direction: row !important;
            padding-left: var(--s-spacing);
            padding-right: 0;
            align-items: center;
            position: relative;
            border-radius: var(--general-border-radius);

            &:hover {
                cursor: pointer;
            }

            .selected-category {
                background-color: var(--general-background-color);
            }
        }

        select-content-category {
            padding-block: var(--m-spacing);
            padding-inline: 40px 30px;
        }
    }
}