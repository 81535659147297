hashtag-suggestions {
    --single-item-height: 40px;

    position: absolute;
    left: 0;
    top: calc(45px + var(--m-spacing));
    z-index: 10;

    display: flex;
    width: calc(100% - 2 * var(--spacing));
    min-height: var(--single-item-height);
    max-height: calc(var(--single-item-height) * 4);
    overflow: hidden;
    margin: 0 var(--spacing);

    background: var(--elevated-background-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);

    @media @desktop-up {
        margin: 0;
        width: 100%;
    }

    .spinner {
        opacity: 0.5;
        margin: auto;
    }

    ul {
        width: 100%;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        overscroll-behavior: contain;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
    }

    li {
        display: flex;
        height: var(--single-item-height);
        padding: var(--s-spacing) var(--spacing);
        box-sizing: border-box;

        &:hover {
            background: var(--background-highlight-color);
            cursor: pointer;
        }
    }

    .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
        gap: var(--s-spacing);
        max-width: 100%;

        margin: 0;

        color: var(--elevated-text-color);
        font-size: 1rem;
        font-weight: 600;
        line-height: 130%;

        .tag {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .featured {
            color: var(--alert-color);
        }
    }

    .no-results {
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;

        p {
            margin: 0;
            line-height: 150%;
        }

        rl-icon {
            font-size: 1.6rem;
        }
    }
}