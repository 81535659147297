.campaign {
    --max-width: var(--max-width-medium);
    max-width: var(--max-width);
    margin: 0 auto;    
}

.campaign-itemlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 15px 0 15px;

    .campaign-itemlist-item {
        width: 25%;
        height: 75px;
        box-sizing: border-box;
        padding-bottom: 15px;
        position: relative;

        .campaign-itemlist-item-inner {
            display: block;
            height: 100%;
            width: 100%;
            border-radius: var(--general-border-radius);
            position: relative;
            transition: all 0.5s ease-in-out;
            -webkit-perspective: 900000px;
            perspective: 900000px;

            &.overdue {
                .campaign-itemlist-item-inner-background {
                    opacity: .25;
                }
            }

            &.open:not(.overdue) {
                background-color: #CECECE;

                .campaign-itemlist-item-inner-door {
                    background-color: #EBEBEB;
                    -webkit-transform: skewY(3deg) scaleX(0.95);
                    transform: skewY(3deg) scaleX(0.95);

                    @media all and (min-width: 400px) and (max-width: 749px) {
                        -webkit-transform: skewY(1.5deg) scaleX(0.97);
                        transform: skewY(1.5deg) scaleX(0.97);
                    }

                    @media all and (min-width: 750px) and (max-width: 1024px) {
                        -webkit-transform: skewY(1deg) scaleX(0.98);
                        transform: skewY(1deg) scaleX(0.98);
                    }

                    @media all and (min-width: 1025px) and (max-width: 1449px) {
                        -webkit-transform: skewY(1.5deg) scaleX(0.97);
                        transform: skewY(1.5deg) scaleX(0.97);
                    }

                    @media all and (min-width: 1450px) {
                        -webkit-transform: skewY(1deg) scaleX(0.98);
                        transform: skewY(1deg) scaleX(0.98);
                    }
                }
            }

            .campaign-itemlist-item-inner-door {
                background-color: #EBEBEB;
                border-radius: var(--general-border-radius);
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
                padding: 10px;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                transform-origin: left;
                transition: all 0.5s ease-in-out;
            }

            h3 {
                font-size: 15px;
                font-weight: bold;
                margin: 0;
                padding: 0;
                color: var(--general-text-color);
                position: relative;
                z-index: 2;
            }

            h4 {
                text-transform: uppercase;
                color: var(--general-text-color);
                font-size: 11px;
                font-weight: normal;
                margin: 0;
                padding: 0;
                opacity: 0.75;
                position: relative;
                z-index: 2;
            }

            rl-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                margin-top: -15px;
                margin-left: -15px;
                font-size: 26px;
                opacity: 0.15;
                z-index: 2;
            }

            .title-placeholder {
                display: block;
                height: 15px;
                width: 70%;
            }

            .sort-placeholder {
                display: block;
                height: 15px;
                width: 40%;
            }
        }

        .campaign-itemlist-item-inner-background {
            background-position: center center;
            background-size: cover;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
        }
    }

    &.campaign-view-biggrid {
        .campaign-itemlist-item {
            width: 50%;
            height: 165px;

            &:nth-child(odd) {
                padding-right: 7.5px;
            }

            &:nth-child(even) {
                padding-left: 7.5px;
            }

            &:nth-child(4n-2) {
                height: 215px;

                &:last-child {
                    height: 165px;
                }
            }

            &:nth-child(4n-1) {
                height: 215px;
                top: -50px;
                margin-bottom: -50px;
            }

            .campaign-itemlist-item-inner {
                &.locked-with-image {
                    position: relative;

                    &:after {
                        content: '';
                        z-index: 1;
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, 0.2);
                    }

                    rl-icon {
                        color: var(--general-background-color);
                        opacity: 1;
                    }
                }

                rl-icon {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    text-align: center;
                    margin-top: -30px;
                    margin-left: -30px;
                    font-size: 50px;
                }
            }
        }
    }
}
