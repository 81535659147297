social-post-create { 
    .scroll-container {
        position: relative;
    }

    .rl-new-form {
        padding: 0;
        overflow-y: auto;
        justify-content: flex-start;

        & > :first-child {
            padding-top: var(--spacing);
        }
    }

    .language-selector-wrapper {
        display: flex;
        justify-content: space-between;
        margin-inline: 0 var(--s-spacing);

        background-color: var(--general-background-color);
        border-bottom: 1px solid var(--general-border-color);

        language-selector {
            flex: 1;
            overflow: hidden;
        }
    }

    .social-group-select {
        padding: var(--spacing) var(--spacing) 0;

        .text-field {
            display: flex;
            gap: var(--s-spacing);
        }

        .group-select-text {
            color: var(--general-text-inactive-color);

            b {
                color: var(--general-text-color);
            }
        }
         
        .users-icon {
            padding-inline: 3px;
        }

        .field-postfix {
            margin-inline: auto 0;
        }
    }

    .social-post-create {
        .save button {
            width: 100%;
            color: var(--white-color);
            background: var(--button-background-color);
            text-transform: uppercase;
        }

        .social-post-create-inner {
            position: static;
            display: flex;
            flex-direction: column;
            gap: var(--spacing);
            padding: 0 var(--spacing) 75px;
        }

        .translation-exclusion-btn {
            margin: var(--s-spacing) 0;
            border-radius: var(--general-border-radius);
        }

        social-poll {
            margin: 0;
            transition: padding 0.3s ease;

            &.bordered {
                padding: var(--m-spacing);
                border: 1px solid var(--general-border-color);
                border-radius: var(--general-border-radius);
                &:has(+ .validation-message .required-field-empty) {
                    border-color: var(--alert-color);
                }
                &:has(+ .validation-message .error-message) {
                    border-color: var(--warning-color);
                }
            }
        }

        .attachments-wrapper {
            margin-bottom: var(--spacing);
            padding: var(--spacing);
            background-color: var(--background-highlight-color);
            border-radius: var(--general-border-radius);
        }
    }

    .social-post-create-loader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.5);
        .flex-center-center();

        .spinner {
            border-color: #fff;
            height: 40px;
            width: 40px;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }
}
