// Local variables
@icon-fa: 'Font Awesome\ 6 Pro';
@icon-fab: 'Font Awesome\ 6 Brands';

@icon-mdi: 'Material Design Icons';

@level-popup: 200;
@level-menu: 350;
@level-profile-navigation: 360;
@level-modal-page: 370;
@level-walkthrough: 400;
@level-modal: 410;

@level-search-viewer: 480;
@level-department-viewer: 490;
@level-profile-viewer: 500;
@level-media-viewer: 600;

@level-department-selector: 800;
@level-selectlist: 800;
@level-actionsheet: 900;
@level-upload-progress: 1000;
@level-add-to-home: 1100;
@level-confirm: 2000;
@level-loader-overlay: 99999;

@base-dark-color-faded: #aaaa97;

@success-color: #59d68e;
@success-darker-color: #2e7d32;
@error-color: #e9806e;
@error-darker-color: #c62828;
@checkbox-checked-color: #3fc59d;
@checkbox-unchecked-color: #8f9091;

// Shadow
@shadow-box: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
@shadow-box-deep: 0 16px 20px rgba(0, 0, 0, .4);

// Colors
@color-primary: #029be5;
@color-primary-accent: #fff;

@color-background-light: #f0f0f0;
@color-background-light-border-color: #e9e9e9;

@color-red: #ED4248;
@color-green: #6aab1f;
@color-blue: #508AE4;
@color-orange: #FF5722;

@clr-highlight: #5FA3F1;
@clr-bodytext: #3c4858;
@clr-subheadline-onbg: #8893A3;
@clr-subheadline: #C0C8D4;
@clr-background: #F7F8FA;
@clr-borders: #EBECEE;
@clr-confirmation: #4FC990;
@clr-warning: #EB6262;
@clr-alert: #FFDA48;

@button-default: #3779f9;

// Sizes
@news-ticker-height: 40px;
@news-ticker-height-mobile: 5vh;
// Configurable from the Portal

@home-background-color: #e9e9e9;
@home-tile-border-color: rgba(255, 255, 255, 0.6);

@font-family: 'Avenir Next LT Pro', Arial, sans-serif;
@dyslexia-font-family: 'OpenDyslexic';
@font-size: 15px;
@font-weight: 400;
@font-weight-headline: 500;

@header-text-color: #fff;
@header-background-color: #303030;

@split-color: #d3d7dc;

@swipe-height: 34px;
@swipe-width: 200px;
@swipe-dot-spacing: 4px;

// Media
@mobile: "767px";

@tablet-min: "768px";
@tablet: "1024px";

@desktop-min: "1025px";
@desktop-big-min: "1300px";

@mobile-max: ~ "only screen and (max-width: @{mobile})";

@tablet-up: ~ "only screen and (min-width: @{tablet-min})";
@tablet-max: ~ "only screen and (max-width: @{tablet})";
@tablet-only: ~ "only screen and (min-width: @{tablet-min}) and (max-width: @{tablet})";

@desktop-up-to-desktop-big: ~ "only screen and (min-width: @{desktop-min}) and (max-width: @{desktop-big-min})";

@desktop-big-up: ~ "only screen and (min-width: @{desktop-big-min})";
@desktop-up: ~ "only screen and (min-width: @{desktop-min})";
@desktop-big-max: ~ "only screen and (max-width: @{desktop-big-min})";

@iphone-x: ~ "only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)";
@ipad-pro: ~ "only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)";

:root {
    --spacing: 15px;
    --m-spacing: 10px;
    --s-spacing: 7px;
    --popup-height-shift: 0px;
    
    --max-width: 600px;
    --max-width-medium: 800px;
    --max-width-large: 1000px;

    --header-background-color: @header-background-color;
    --header-text-color: @header-text-color;
    --header-text-color-rgb: 255, 255, 255;
    --button-background-color: #000;
    --button-text-color: #fff;
    --native-statusbar-bg-color: @header-background-color;
    --native-statusbar-text-color: @header-text-color;

    --popup-overlay-bg: rgba(0, 0, 0, 0.5);

    // New Color Variables  
    --general-text-color: #3C4858;
    --general-text-color-rgb: 60, 72, 88;
    --general-background-color: #FFFFFF;
    --general-background-color-rgb: 255, 255, 255;
    --general-border-color: ~'rgba(var(--general-text-color-rgb), .1)';
    --general-text-inactive-color: ~'rgba(var(--general-text-color-rgb), .4)';
    --shadow-color: rgba(0, 0, 0, 0.15);

    --background-highlight-color: #F5F5F5;
    --background-highlight-color-rgb: 245, 245, 245;
    --highlight-color: #5FA3F1;
    --highlight-color-rgb: 95, 163, 241;
    --text-on-highlight-color: #FFFFFF;
    --text-on-highlight-color-rgb: 255, 255, 255;
    --locked-field-color: rgba(var(--general-text-color-rgb), 0.1);

    --confirmation-color: #4FC990;
    --confirmation-color-rgb: 79, 201, 144;
    --alert-color: #FED263;
    --alert-color-rgb: 254, 210, 99;
    --warning-color: #EB6262;
    --warning-color-rgb: 235, 98, 98;
    --info-color: #5FA3F1;;
    --info-color-rgb: 95, 163, 241;

    --loadbar-color: #29d;
    --sidemenu-current-text-color: #e0e0e0;
    --sidemenu-background-color: #fff;
    --sidemenu-text-color: #111;
    --desktop-background-color: #3c4858;
    --desktop-text-color: #fff;

    --navigation-text-color: #3C4858;
    --navigation-text-color-rgb: 60, 72, 88;
    --navigation-background-color: #FFFFFF;
    --navigation-background-color-rgb: 255, 255, 255;

    --black-color: #000000;
    --black-color-rgb: 0, 0, 0;
    --white-color: #fff;
    --white-color-rgb: 255, 255, 255;
    --text-on-white-color: #3C4858;
    --text-on-white-color-rgb: 60, 72, 88;

    --quiz-color: #FEB663;
    --quiz-color-rgb: 254, 182, 99;
    --service-form-color: #7D548D;
    --service-form-color-rgb: 125, 74, 141;


    --general-border-radius: 7px;
    --chat-border-radius: 15px;

    // Module Variables
    // Training PRO
    --training-border-radius: 7px;
    --training-lesson-image-height: 50%;

    //Catalog
    --catalog-border-radius: 7px;

    //Badges
    --badge-background-color: #ff0404;
    --badge-text-color: #fff;
    --sab: env(safe-area-inset-bottom);
    --sat: env(safe-area-inset-top);
    --max-desktop-sheet-height: 500px;
    --max-mobile-sheet-height: 60vh;

    //elevated colors
    --elevated-text-color: var(--general-text-color);
    --elevated-text-color-rgb: var(--general-text-color-rgb);
    --elevated-text-inactive-color: rgba(var(--elevated-text-color-rgb), 0.4);
    --elevated-border-color: rgba(var(--elevated-text-color-rgb), 0.1);
    --elevated-background-color: var(--general-background-color);
    --elevated-background-color-rgb: var(--general-background-color-rgb);
    --elevated-background-highlight-color: var(--background-highlight-color);
    --elevated-background-highlight-color-rgb: var(--background-highlight-color-rgb);
}