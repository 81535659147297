multiple-permission-selector-wrapper {
    display: flex;
    flex-direction: column;

    .permissions-label-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--general-border-color);
    }

    .permissions-selectors-wrapper {
        border-radius: var(--general-border-radius);
        overflow: hidden;
    }

    .permission-selector-label {
        flex: 1;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 10px 7px;
    }

    .remove {
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        color: var(--warning-color);
        padding: 15px 10px 7px;
    }

    .permissions-set {
        display: flex;
        flex-direction: column;
    }
}

#permissions.not-multiple-permissions {
    &:after {
        border: solid var(--general-text-color);
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 10px;
        margin-top: -5px;
        opacity: .5;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: rotate(-45deg);
        width: 10px;
    }
}

multiple-permission-selector:not(:last-child) {
    border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
}