@import "./AddUsers/AddUsers";
@import "./ChatMembers/ChatMembers";
@import "./ChannelsList/ChannelsList";
@import "./ChatSettings/ChatSettings";
@import "./Conversation/Conversation";
@import "./Conversation/MessageViewers/MessageViewers";
@import "./CreateChat/CreateChat";

.base-view-chat {
    height: auto !important;
    position: relative;
    background: var(--general-background-color);
}

chat {
    max-width: var(--max-width);
    margin: 0 auto;
    position: relative;
    display: block;
    padding-bottom: 50px;

    .lazy-placeholder {
        padding: 15px;
        display: flex;
        align-items: center;

        @media @desktop-up {
            max-width: 60vw;
        }

        .profile-image-lazy {
            width:40px;
            height:40px;
            min-width: 40px;
            background-color: rgba(var(--general-text-color-rgb), 0.15);
            border-radius: 50%;
            content: "";
        }

        rl-loading-placeholder {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            width: 80vw;

            .placeholder-shimmer {
                height: 16px;
            }
        }
    }

    .channel-image {
        border-color: var(--general-background-color);
    }
}

chat, conversation {
    .channel-image {
        background: var(--background-highlight-color);
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        padding: 0;

        .profile-image {
            width: 100%;
            height: 100%;
        }
    }

    .group-image {
        position: relative;
        border-radius: 50%;
        z-index: 5;
        overflow: hidden;
        width: 40px;
        height: 40px;
        min-width: 40px;
        background-color: var(--background-highlight-color);

        profile-image {
            padding: 0;
        }

        .channel-image {
            &:nth-child(2),
            &:nth-child(3) {
                position: absolute;
                min-width: 0;
                top: 0;
                width: 50%;
                left: 50%;
                border-left-width: 0.4px;
                border-left-style: solid;

                .profile-image {
                    border-radius: 0;
                }
            }
        }

        &.group-image-2 {
            .channel-image {
                &:nth-child(2) {
                    border-radius: 0 50% 50% 0;
                }
            }
        }

        &.group-image-3 {
            .channel-image {
                &:nth-child(2) {
                    height: 50%;
                    border-radius: 0 50% 0 0;
                }

                &:nth-child(3) {
                    height: 50%;
                    top: 50%;
                    border-radius: 0 0 50% 0;
                    border-top-width: 0.4px;
                    border-top-style: solid;
                }
            }
        }
    }

    .empty-screen {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        rl-icon {
            font-size: 4rem;
            color: var(--general-text-inactive-color);
        }

        .subtitle, 
        .title {
            padding: 0 var(--spacing);
            line-height: 135%;
            max-width: 252px;
            text-align: center;   
            margin: 0;     
            font-size: 0.8rem;
        }

        .title {
            font-size: 1rem;
            font-weight: bold;
            margin-top: var(--m-spacing);
        }

        rl-button {
            margin-top: var(--spacing);
            width: 100%;
            max-width: 252px;
        }
    }
}

.chat-leave-confirm {
    z-index: 500;

    .yes {
        font-size: 15px;
        color: var(--highlight-color);
    }

    .no {
        font-size: 15px;
        color: var(--warning-color);
        font-weight: normal;
    }
}

.channels-list-action-sheet {
    .actionsheet-header {
        padding: 0 0 var(--s-spacing) 0 !important;
    }
}

.failed-chat-connect {
    top: 30vh;
}