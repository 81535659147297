search-results {
    display: block;
    width: 100%;

    .search-results {
        .results-wrapper {
            display: flex;
            flex-direction: column;
            gap: calc(var(--m-spacing) * 2);
            padding: 0 0 var(--spacing);
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            &.hidden {
                opacity: 0;
            }
        }
    }

    .help-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 90px auto 0;
        max-width: 250px;
        text-align: center;

        rl-icon {
            position: relative;
            font-size: 56px;
            margin-bottom: 20px;
            color: var(--general-text-inactive-color);
        }

        h4 {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
        }

        p {
            margin: 0;
            font-size: 13px;
        }

        @media @desktop-up {
            max-width: 320px;

            h4 {
                margin-bottom: 10px;
            }

            rl-icon {
                margin-bottom: 25px;
            }
        }
    }
}