.scorm-page {
    position: relative;
    padding-bottom: 0;
    height: 100%;
    overflow: visible !important;

    @media @desktop-up {
        height: calc(100vh - 60px);
    }

    .iframe-container {
        -webkit-overflow-scrolling: touch;
        position: relative;
        height: 100%;
        min-height: 100%;
        overflow: visible;

        icon {
            position: absolute;
            top: 10px;
            right: 10px;

            color: var(--general-background-color);
            background-color: var(--general-text-color);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 6px;

            &.landscape {
                @media screen and (orientation: landscape) {
                    display: none;
                }
            }

            &.portrait {
                transform: scaleY(-1) rotate(90deg);

                @media screen and (orientation: portrait) {
                    display: none;
                }
            }


        }

        iframe {
            width: 1px;
            min-width: 100%;
            height: 99%;
            border: 0;
            position: static;
        }
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(-75px / 2);
        margin-left: calc(-75px / 2);

        width: 75px;
        height: 75px;
    }
}
