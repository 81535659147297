@import "Content/ContentCreatePopup/ContentCreatePopup";
@import "Content/ContentCommentsPopup/ContentCommentsPopup";
@import "Content/ContentItem/CountentItem";
@import "Content/ContentSocialSharing/ContentSocialSharingComponent";
@import "Content/ContentSharesPopup/ContentSharesPopupComponent";
@import "Content/Content";
@import "ContentCategories/ContentCategories";
@import "LiveTile/ContentLiveTile";

@content-footer-height: 55px;

.base-view.base-view-content, .base-view.base-view-contentItem {
    background: var(--background-highlight-color);
    height: auto;
}

content-wrapper {
    position: relative;
    display: block;
    padding: var(--spacing) 0;

    &:has(module-search-filters) {
        padding-top: 0;
    }

    & > * {
        padding-inline: 0;

        @media @mobile-max {
            padding-inline: var(--spacing);
        }
    }

    .search-wrapper {
        position: relative;

        &.sticky {
            z-index: 10;
            position: sticky;
            top: 55px;
            background: var(--background-highlight-color);

            @media @desktop-up {
                top: 60px;
            }
        }
    }

    .no-content {
        position: relative;
        margin-top: 90px;
        top: unset;
        left: unset;
        right: unset;
    }

    .go-to-feed {
        display: block;
        padding-bottom: 20px;
    }
}

