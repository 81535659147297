.base-view-user-management {
    height: auto !important;

    icon-plus {
        button {
            background-color: var(--button-background-color);
        }
    }
}

.user-management {
    --footer-height: 50px;
    --mobile-header-height: 55px;
    --desktop-header-height: 60px;

    display: flex;
    justify-content: space-between;

    @media @desktop-up {
        max-width: unset;
    }

    .placeholder-shimmer {
        height: 65px;
    }

    .filters-section {
        flex: 2 1 40%;
        display: flex;
        flex-direction: column;
        max-width: min(40%, 450px);
        border: 1px solid var(--general-border-color);
        border-radius: var(--general-border-radius);
        margin: var(--spacing);

        .subheader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: var(--spacing);

            h2 {
                font-weight: 700;
                font-size: 1rem;
                margin: 0;
            }

            button {
                padding: 0
            }
        }

        .content {
            max-height: calc(100vh - 220px);
            overflow: auto;
        }
    }

    .main-section {
        display: flex;
        flex-direction: column;
        max-width: var(--max-width);
        margin: 0 auto;
        flex: 1 1 60%;
        max-height: calc(100vh - var(--mobile-header-height) - var(--footer-height));
        overflow-y: hidden;

        @media @desktop-up {
            max-height: calc(100vh - var(--desktop-header-height));
        }

        .fixed-top {
            position: sticky;
            z-index: 1;
            top: 0;
            width: 100%;
            background: var(--general-background-color);
            box-sizing: border-box;
            padding: 0 var(--spacing);

            .top {
                @media @desktop-up {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    gap: var(--spacing);
                    padding: var(--spacing) 0 0;
                }

                .user-management-search {
                    display: flex;
                    padding: var(--spacing) 0 0;

                    rl-search {
                        flex: 1;
                    }

                    @media @desktop-up {
                        flex: 1;
                        padding: 0;
                    }

                    .info-wrapper {
                        position: relative;
                        margin-left: var(--spacing);

                        rl-icon {
                            height: 100%;
                        }
                    }
                }

                .filters-wrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: var(--s-spacing);
                    padding: var(--m-spacing);
                    background-color: transparent;
                    border: 1px solid var(--general-border-color);
                    border-radius: var(--general-border-radius);
                    height: 45px;
                    box-sizing: border-box;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(var(--general-text-color-rgb), 0.03);
                    }

                    p {
                        margin: 0;
                    }

                    .filters-count {
                        display: none;
                    }

                    &.active {
                        font-weight: 700;
                        color: var(--highlight-color);
                        background-color: rgba(var(--highlight-color-rgb), 0.1);
                        border: 1px solid transparent;

                        .filters-count {
                            display: inline;
                        }

                        &:hover {
                            background-color: rgba(var(--highlight-color-rgb), 0.15);
                        }
                    }
                }

                .user-management-create-link {
                    box-sizing: border-box;
                    text-align: center;
                    width: auto;
                    padding: 0;
                }
            }

            .rl-tabs {
                .rl-tab {
                    min-width: 50px;
                }

                rl-badge {
                    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
                    font-weight: bold;
                }

                // hide tabs container if only one tab is displayed
                &:has(> *:only-child) {
                    display: none;
                }
            }
        }

        .user-management-scroll-container {
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            padding: 50px var(--spacing);
            margin-top: -50px; // allow tip with info to show
    
            &:has(.no-content) {
                height: 100vh;
            }
        }

        .empty-states-container {
            position: relative;
            flex: 1;

            .empty-state {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                gap: var(--s-spacing);

                &.empty-search {
                    transition-delay: 0.3s;
                }
            }

            .create-user-mobile-btn {
                margin-top: var(--s-spacing);
            }
        }

        .no-content {
            position: static;
            margin-top: var(--spacing);
            min-width: max-content;
            height: unset;

            span {
                line-height: 2rem;
            }

            i {
                margin: var(--s-spacing) auto;
                font-size: 64px;
                color: var(--general-text-inactive-color);
            }

            p {
                max-width: 255px;
            }
        }
    }

    .module-pre-loader {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding: 0 var(--spacing);
        margin: var(--spacing) 0;

        .placeholder-top-line {
            rl-loading-placeholder {
                display: flex;
                gap: var(--m-spacing);
                height: 45px;

                .placeholder-shimmer {
                    border-radius: var(--general-border-radius);
                    height: 45px;
                }

                & > * {
                    &:first-child {
                        flex-basis: 65%;
                    }

                    &:nth-child(2) {
                        flex-basis: 15%;
                    }

                    &:last-child {
                        flex-basis: 20%;
                        background-color: var(--general-text-inactive-color);
                    }

                    &:only-child {
                        flex-basis: 100%;
                        background-color: inherit;
                    }
                }
            }
        }

        .placeholder-user {
            display: flex;
            gap: var(--s-spacing);
        }

        .placeholder-image-container {
            position: relative;

            .placeholder-avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: block;
            }

            .placeholder-status {
                position: absolute;
                bottom: -2px;
                right: 0;
                background-color: var(--general-text-inactive-color);
                border-radius: 50%;
                height: 18px;
                width: 18px;
                backdrop-filter: blur(15px);
            }
        }

        .placeholder-user-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;

            .placeholder-name {
                .placeholder-shimmer {
                    height: 21px;
                    border-radius: 1000px;
                }
            }

            .placeholder-department {
                .placeholder-shimmer {
                    height: 12px;
                    border-radius: 1000px;
                }
            }
        }
    }
}

.user-management-update {
    padding: 20px 20px 10px 20px;

    .user-management-update-popup-content {
        overflow-y: auto;
    }

    .image-container {
        width: 100%;
        height: 100px;
        text-align: center;
        position: relative;
        margin-top: 20px;

        .image-box {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -50px;
            margin-left: -50px;
            height: 100px;
            width: 100px;
            border-radius: 50%;

            .profile-image {
                height: 100px;
                width: 100px;
                border-width: 3px;
            }

            .disabled {
                .profile-image {
                    opacity: 0.5;
                }
            }
        }

        .disabled-indicator {
            position: absolute;
            bottom: 0;
            right: 50%;
            transform: translate(50%, 0);
            padding: 0 var(--s-spacing);
            color: var(--white-color);
            background-color: var(--warning-color);
            font-size: 0.675rem;
            border-radius: var(--general-border-radius);
            font-weight: 600;
            line-height: 1.7rem;
            display: flex;
            align-items: center;
            gap: 4px;

            rl-icon {
                font-size: 0.75rem;
            }
        }
    }

    .popup-sticky-bottom {
        .pending-user-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: var(--spacing);

            rl-button {
                flex: 1 0 47%;
                max-width: 100%;
                transition: max-width 0.3s ease;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .approve {
                button {
                    background-color: var(--confirmation-color);
                    color: var(--white-color);
                }

                &.with-spinner {
                    rl-icon {
                        animation: rotation 2s infinite linear;
                    }
                }
            }

            .decline {
                button {
                    background-color: var(--warning-color);
                    color: var(--white-color);
                }

                &.hidden {
                    max-width: 0;
                    overflow: hidden;
                }
            }
        }
    }
}

.user-management-create, .user-management-update {
    .selector-postfix {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: var(--general-text-inactive-color);
    }
    
    .divider-line {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--general-border-color);
        margin: var(--m-spacing) 0;
    }

    .form-item:last-child {
        padding-bottom: 75px;
    }

    .toggles {
        .form-item:first-child {
            .toggle-field {
                border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
            }
        }

        .form-item:last-child {
            .toggle-field {
                border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
            }
        }

        .form-item:only-child {
            .toggle-field {
                border-radius: var(--general-border-radius);
            }
        }
    }

    .pre-loader {

        .placeholder-avatar {
            margin: 20px auto 0;
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }

        .divider-line {
            margin: var(--spacing) 0;
        }

        .placeholder-form {
            padding: var(--spacing) var(--spacing) 0;
            display: flex;
            flex-direction: column;
            gap: var(--spacing);
        }

        .placeholder-form-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        .placeholder-label {
            .placeholder-shimmer {
                border-radius: 1000px;
                height: 20px;
                margin-left: var(--m-spacing);
            }
        }

        .placeholder-input {
            .placeholder-shimmer {
                height: 45px;
                border-radius: var(--general-border-radius);
            }
        }
    }

    .processing-overlay {
        background-color: rgba(var(--general-background-color-rgb), 0.8);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    header-popup {
        position: sticky;
        z-index: 3;
        top: 0;
        width: 100%;
        box-sizing: border-box;
    }

    .form {
        padding: 15px;
        overflow-y: auto;

        .form-item > label {
            padding: 5.5px 0;
        }
    }

    .form-item {
        position: relative;
    }

    autocomplete .select-area, autocomplete .search-wrapper input {
        border-radius: var(--general-border-radius);
        background: var(--background-highlight-color);
        overflow: hidden;
        font-weight: 400;

        &.disabled {
            background: var(--locked-field-color);
            border: none;
            color: var(--general-text-inactive-color);
            opacity: 1;
        }
    }

    .selected-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 10px 0;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 30px;
            max-width: 100%;
            padding: 0 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            line-height: 30px;
            border-radius: var(--general-border-radius);
            background: var(--background-highlight-color);
            position: relative;
            overflow: hidden;

            p {
                margin: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                font-size: 15px;
            }

            .remove-item {
                position: relative;
                top: 1px;
                font-weight: 600;
                color: var(--button-background-color);
                padding: 1px 2px 1px 8px;
                font-size: 15px;
            }

            [icon="lock"] {
                opacity: 0.4;
                padding-left: 8px;
                font-size: 13px;
            }
        }
    }

    .action-container {
        margin: 0 var(--spacing);
        display: flex;
        flex-direction: column;
        gap: var(--spacing);
        padding-bottom: 75px;

        rl-button {
            button {
                font-size: 1rem;
                font-weight: 400;
                text-align: left;
                background-color: var(--background-highlight-color);
                display: flex;
                align-items: center;
                gap: 4px;
            }

            &.warning {
                color: var(--warning-color);
            }
        }
    }

    .save {
        button {
            color: var(--white-color);
            background: var(--button-background-color);
        }

        &.with-spinner {
            rl-icon {
                animation: rotation 2s infinite linear;
            }
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}

.user-management-create {
    .form {
        padding-bottom: 90px;
    }
}

@import "ActivityWidget/ActivityWidget";
@import "UserManagementFilters/UserManagementFilters";
@import "ActivityIndicator/ActivityIndicator";
@import "UserManagementUserList/UserManagementUserList";
@import "UserManagementConfirmPopup";