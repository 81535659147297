module-search-filters {
    display: flex;
    flex-direction: column;

    padding-top: var(--spacing);
    padding-bottom: var(--spacing);
    background-color: var(--background-highlight-color);
    margin: 0 -1px;

    &:has(.with-filters) {
        gap: var(--m-spacing);

        .filters:last-child {
            padding-right: var(--spacing);
        }
    }

    rl-search {
        input {
            background-color: rgba(var(--general-text-color-rgb), 0.1);
            backdrop-filter: blur(12px);
            border: 1px solid var(--general-border-color);
        }

        &:hover {
            input {
                background-color: rgba(var(--general-text-color-rgb), 0.13);
            }
        }

        &.error input {
            border: 1px solid var(--warning-color);
        }

        rl-icon {
            z-index: 1;
        }

        rl-icon[icon="close"] {
            background-color: rgba(var(--general-text-color-rgb), 0.2);
            color: var(--white-color);
            width: 22px;
            height: 22px;
            margin: 0 var(--m-spacing);
            transform: translate(0, 50%);

            &:hover {
                cursor: pointer;
                background-color: rgba(var(--general-text-color-rgb), 0.3);
            }

            span {
                font-weight: 400;
                font-size: 0.85rem;
                margin: 1px 0 0 1px;
            }
        }
    }

    rl-toggle-button > div {
        height: unset;

        rl-icon {
            display: none;
        }
    }

    .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--s-spacing);
        overflow-x: auto;
        padding-bottom: 2px;

        @media @desktop-up {
            overflow-x: visible;
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            order: 1;
            flex-shrink: 0;

            &.active {
                order: -1;
            }
        }
    }

    scroll-shadow[direction=horizontal] {
        display: none;

        .gradient {
            &.last {
                background-image: linear-gradient(to right, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
            }

            &.first {
                background-image: linear-gradient(to left, hsla(0, 0%, 100%, 0), var(--background-highlight-color));
            }
        }

        &.with-filters {
            display: block;
        }
        
        @media @desktop-up {
            .gradient {
                width: 0;
            }
        }
    }

    rl-button.clear-filters {
        font-weight: 600;

            button {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            gap: 3px;
            max-height: 36px;

            min-width: unset;
            padding: 0;

            font-size: 0.85rem;
            color: var(--warning-color);

            &:hover {
                color: rgba(var(--warning-color-rgb), 0.9)
            }

            rl-icon {
                font-size: 1rem;

                span {
                    font-weight: 400;
                }
            }
        }
    }
}
