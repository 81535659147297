.base-view-hashtagsOverview {
    background: var(--background-highlight-color);

    &.base-view {
        height: auto;
    }
}

hashtags-overview {
    display: block;

    .rl-tab {
        @media @tablet-max {
            flex: 1;
        }
    }

    .tab-content {
        padding: var(--spacing) 0;

        @media @tablet-max {
            padding: var(--spacing);
        }
    }
}