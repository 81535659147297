catalog-search { 
    display: block;
    
    .search-header {
        module-search-filters {
            background: var(--general-background-color);
        } 
  
        &.sticky {
            z-index: 10;
            position: sticky;
            top: 55px;

            @media @desktop-up {
                top: 60px;
            }
        }
    }
}
