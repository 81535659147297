social-poll {
    position: relative;
    display: block;
    min-height: 40px;

    .add-poll {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        height: 40px;
        padding: 10px;

        color: var(--highlight-color);
        background: ~'rgba(var(--highlight-color-rgb), 0.1)';

        border: 1px ~'solid rgba(var(--highlight-color-rgb), 0.1)';
        box-sizing: border-box;
        border-radius: var(--general-border-radius);

        .add-poll-title {
            margin-top: 2px;
            margin-left: 5px;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
        }

        [icon=add] {
            margin-left: auto;
            font-size: 15px;
        }
    }

    .settings {
        padding-bottom: 10px;

        > div {
            display: flex;
            align-items: center;
            height: 45px;

            rl-icon {
                margin-right: 10px;
                font-size: 17px;
            }

            rl-toggle {
                margin-left: auto;
            }
        }
    }

    .poll {
        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            div {
                flex: 1;
                display: flex;

                > span {
                    margin-left: 5px;
                }
            }

            button {
                padding: 0;
            }

            rl-icon {
                width: 19px;
                vertical-align: top;

                &[icon=settings] {
                    opacity: 0.5;
                }
            }

            .header-title {
                font-size: 15px;
                font-weight: bold;
            }

            input {
                flex: 1;
                height: 19px;
                margin-left: 5px;
                padding: 0;

                font-weight: bold;
                font-size: 15px;
                line-height: 19px;
                border: none;

                &::placeholder {
                    color: ~'rgba(var(--general-text-color-rgb), 0.4)';
                }
            }
        }

        .poll-options {
            .option {
                position: relative;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }

                input, .text {
                    width: 100%;
                    min-height: 40px;
                    padding: 10px 40px 10px 10px;

                    font-size: 13px;
                    line-height: 18px;

                    border: 1px solid ~'rgba(var(--general-text-color-rgb), 0.15)';
                    border-radius: var(--general-border-radius);
                    box-sizing: border-box;
                    -webkit-appearance: none;
                }

                .text {
                    position: relative;
                    text-align: left;
                    -webkit-appearance: none;
                    word-wrap: break-word;

                    &.voted {
                        color: var(--highlight-color);
                        font-weight: bold;

                        border-left-width: 3px;
                        border-right-width: 3px;
                        border-color: var(--highlight-color);
                    }

                    &.clickable {
                        cursor: pointer;
                    }

                    &[disabled] {
                        -webkit-tap-highlight-color: transparent;
                    }

                    .percent {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        background: ~'rgba(var(--highlight-color-rgb), 0.1)';
                    }
                }

                input {
                    &::placeholder {
                        color: ~'rgba(var(--general-text-color-rgb), 0.4)';
                    }
                }

                .option-votes {
                    display: flex;
                    margin-top: 5px;
                    align-items: center;

                    user-portraits {
                        margin-right: 5px;

                        div {
                            width: 20px;
                            height: 20px;
                        }

                        div:not(:last-of-type) {
                            margin-right: -5px;
                        }
                    }

                    span {
                        text-transform: lowercase;
                        opacity: 0.4;
                        font-size: 13px;
                        line-height: 16px;
                    }
                }

                .option-button {
                    position: absolute;
                    right: 0;
                    top: 0;

                    padding: 0;
                    height: 40px;
                    width: 40px;

                    rl-icon {
                        height: 40px;
                        font-size: 15px;
                        opacity: 0.4;
                    }
                }
            }
        }

        footer {
            display: flex;
            justify-content: space-between;
            margin: 10px 0 0 0;

            .deadline {
                flex: 1;
                display: flex;
                align-items: center;
                padding-right: 10px;
                font-size: 13px;
                line-height: 16px;

                .label {
                    margin-right: 5px;
                }

                input {
                    flex: 1;
                    vertical-align: middle;
                    height: 27px;
                    padding: 0 10px;

                    font-size: 13px;
                    line-height: 16px;
                    border-radius: var(--general-border-radius);

                    border: none;
                    background: var(--background-highlight-color);
                    -webkit-appearance: none;
                }

                .never {
                    display: flex;
                    align-items: center;

                    height: 27px;
                    padding: 0 10px;

                    border-radius: var(--general-border-radius);
                    background: var(--background-highlight-color);

                    > span {
                        margin: 0 5px;
                        font-size: 13px;
                        line-height: 16px;
                        font-weight: 700;
                    }

                    [icon=clock] {
                        font-size: 12px;
                    }

                    [icon=arrow-bottom] {
                        margin-top: 2px;
                        font-size: 8px;
                    }
                }
            }

            .remove-poll {
                height: 27px;
                padding: 0 10px;
                margin-inline: auto 0;

                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: ~'var(--warning-color)';
                background: ~'rgba(var(--warning-color-rgb), 0.1)';

                border-radius: var(--general-border-radius);
            }

            .deadline {
                font-size: 13px;
                line-height: 16px;

                .time-left {
                    text-transform: lowercase;
                    padding-left: 5px;
                    font-weight: bold;
                }
            }

            .votes {
                color: var(--general-text-color);
                text-transform: lowercase;
                opacity: 0.4;
                font-size: 13px;
                line-height: 16px;
            }
        }

        .create-footer {
            flex-wrap: wrap;
            gap: var(--m-spacing);

            .deadline {
                padding-right: 0;

                @media @mobile-max {
                    &:has(input.ng-not-empty) {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}

voters-popup {
    .popup-content-inner > section {
        padding: 0 15px;
        height: ~'calc(100% - 55px)';

        simple-user-list {
            display: block;
            height: 100%;
        }
    }
}

